/* global jQuery ScrollMagic TweenMax TweenMax TimelineMax TweenMax Linear total base_css */
jQuery(function ($) {
  // init controller
  var controller = new ScrollMagic.Controller();
  var ecosystemScene;
  // --------------------Logo Footer ------------ //
  var tweenFooter = new TimelineMax()
    // .add(TweenMax.fromTo( [ $("path#logo-main")], 1, { autoAlpha: 0, rotationX: -270 }, {autoAlpha: 1, rotationX: 0, yoyo:true, ease: Linear.easeInOut}))
    .add(TweenMax.fromTo($("path#logo-main"), 1, { autoAlpha: 0, opacity: 0 }, { autoAlpha: 1, opacity: 1 }))
    .add(
      TweenMax.to([$("path.text-think"), $("path.text-infinity")], 2, {
        transform: "translateX(0, 0)",
        xPercent: 0,
        ease: Linear.easeInOut,
      })
    )
    .add(
      TweenMax.fromTo(
        [$("path.text-behind"), $("path.logo-border")],
        0.5,
        { autoAlpha: 0, opacity: 0 },
        { autoAlpha: 1, opacity: 1 }
      )
    )
    .add(
      TweenMax.fromTo(
        $("path#light1"),
        0.5,
        { opacity: 0 },
        { opacity: 1, fill: "rgb(255, 222, 5)", ease: Linear.easeInOut }
      )
    )
    .add(TweenMax.fromTo($("path#light"), 0.5, { autoAlpha: 0, opacity: 0 }, { autoAlpha: 0.5, opacity: 0.5 }));

  // Create the Scene and trigger when visible
  new ScrollMagic.Scene({
    triggerElement: "#logo-footer",
    triggerHook: "1",
    duration: 450,
    tweenChanges: false,
    offset: 100,
  })
    .setTween(tweenFooter)
    .addTo(controller);
  // show indicators (requires debug extension)
  // .addIndicators();

  // --------------------Consumer Journey ------------ //
  var journeyDetail = $(".product--journey--detail");
  var journey = $(".product__journey__foot");
  var journeyBefore = $('.product__journey__foot--before');
  var journeyAfter = $('.product--journey--bg-img--after');
  var journeyHome = $(".product__journey__home");
  var tweenConsumer = new TimelineMax()
    .add([
      TweenMax.fromTo("#products", 0.3, { autoAlpha: 0 }, { autoAlpha: 1 }),
      TweenMax.fromTo(journey, 0.3, { autoAlpha: 0, opacity: 0.5, y: "15%" }, { autoAlpha: 1, opacity: 1, y: "0%" }),
      TweenMax.fromTo(journeyDetail, 0.3, { autoAlpha: 0, opacity: 0, x: "-15%" }, { autoAlpha: 1, opacity: 1, x: "0%" })
    ])
    .add([
      TweenMax.fromTo(journeyHome, 0.5, { opacity: 0, width: 0 }, { opacity: 1, width: '100%' }),
      TweenMax.fromTo(journeyBefore, 0.5, { opacity: 0, width: 0 }, { opacity: 1, width: '100%' }),
    ])
    .add(TweenMax.fromTo(journeyAfter, 0.5, { opacity: 0 }, { opacity: 1 }))

  new ScrollMagic.Scene({
    triggerElement: ".product--journey",
    triggerHook: "0",
    tweenChanges: true,
    offset: -200,
  })
    .setTween(tweenConsumer)
    .addTo(controller);

  // -------------------- Section Ecosystem ------------ //
  var ecosystemDetail = $(".about--header--text");
  var moveContentElm = $(".ecosystem--move-content");
  var moveImageElm = $(".ecosystem--move--bg img");
  var tweenEcosystem = new TimelineMax()
    .add(TweenMax.fromTo(ecosystemDetail, 0.5, { opacity: 0, y: "15%" }, { opacity: 1, y: "0%" }))
    .add([
      TweenMax.fromTo(moveImageElm, 0.3, { autoAlpha: 0, x: "-100%",  y: "-50%"}, { autoAlpha: 1, x: "-50%", y: "-50%", ease: Linear.easeInOut}),
      TweenMax.fromTo(moveContentElm, 0.3, { autoAlpha: 0, x: "50%" }, { autoAlpha: 1, x: "0%" }),
    ]);

  new ScrollMagic.Scene({
    triggerElement: "#ecosystem",
    triggerHook: "0.3",
    tweenChanges: true,
    duration: 300,
    offset: -100,
  })
    .setTween(tweenEcosystem)
    .setClassToggle(".ecosystem--move", "active")
    .reverse(true)
    .addTo(controller);
  // .addIndicators();

  // --------------------Section Mission ------------ //
  var targetItems = $(".target--content--item");
  var tweenMission = new TimelineMax()
  .add([
    TweenMax.fromTo([$("#mission")], 1, { autoAlpha: 0 }, { autoAlpha: 1 }),
    TweenMax.fromTo([$(".target")], 1, { autoAlpha: 0, opacity: 0, y: "50%" }, { autoAlpha: 1, opacity: 1, y: "0%" }),
  ]);
  targetItems.each(function () {
    tweenMission.add(TweenMax.fromTo($(this), 0.1, { autoAlpha: 0, opacity: 0 }, { autoAlpha: 1, opacity: 1, ease: Linear.easeInOut }));
  });

  new ScrollMagic.Scene({
    triggerElement: ".mission",
    triggerHook: "0.3",
    offset: -300,
    tweenChanges: false,
    duration: 500
  })
    .setTween(tweenMission)
    .addTo(controller)

  // --- Think8 Idea --- //
  new ScrollMagic.Scene({
    triggerElement: ".section-idea",
    triggerHook: "0.3",
    offset: -400,
  })
    .setClassToggle(".idea--content", "fade-in") // add class Fadein
    .addTo(controller)

  // --- Product shoes --- //
  new ScrollMagic.Scene({
    triggerElement: ".product--shoes",
    triggerHook: 0,
    offset: -400,
  })
    .setClassToggle(".product--shoes", "fade-in") // add class Fadein
    .addTo(controller)
    // .addIndicators();

  //  -- Process Roadmap -- //
  let roadmapActive = $(".roadmap--row .col-active");
  let lengthActive = (100 / 6) * (roadmapActive.length - 1) ? (100 / 6) * (roadmapActive.length - 1) : 2;
  let processWidth = "calc(" + lengthActive + "% + 20px)";
  // var addRule = (function (style) {
  //   var sheet = document.head.appendChild(style).sheet;
  //   return function (selector, css) {
  //     var propText =
  //       typeof css === "string"
  //         ? css
  //         : Object.keys(css)
  //             .map(function (p) {
  //               return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
  //             })
  //             .join(";");
  //     sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
  //   };
  // })(document.createElement("style"));

  // -- Section roadmap -- //
  var roadmapItems = $(".roadmap--item.roadmap--active");
  var roadmapTween = new TimelineMax();
  roadmapTween.add(
    TweenMax.fromTo(
      [$(".process--before")],
      1,
      { autoAlpha: 0, opacity: 0, width: 0 },
      { autoAlpha: 1, opacity: 1, width: "100%" }
    )
  );
  // roadmapItems.each(function () {
  //   roadmapTween.add([
  //     TweenMax.fromTo(
  //       [$(this).find(".roadmap--content-pc .before")],
  //       0.8,
  //       { autoAlpha: 0, opacity: 0 },
  //       { autoAlpha: 1, opacity: 1 }
  //     ),
  //     TweenMax.fromTo(
  //       [$(this).find(".roadmap--content-pc .after")],
  //       0.8,
  //       { autoAlpha: 0, opacity: 0, height: 0 },
  //       { autoAlpha: 1, opacity: 1, height: "80%" }
  //     ),
  //     TweenMax.fromTo(
  //       $(this).find(".roadmap--content-pc .roadmap--quarter"),
  //       0.8,
  //       { autoAlpha: 0, opacity: 0 },
  //       { autoAlpha: 1, opacity: 1 }
  //     )
  //   ]);
  // });
  // if (roadmapItems.parent().hasClass("col-active")) {
  //   roadmapTween.add(
  //     TweenMax.fromTo(
  //       [$(this).find(".dot-active"), $(".process--after")],
  //       2,
  //       { autoAlpha: 0, opacity: 0 },
  //       { autoAlpha: 1, opacity: 1 }
  //     )
  //   );
  // }

  roadmapItems.each(function () {
    roadmapTween.add(TweenMax.fromTo($(this), 0.2, { autoAlpha: 0 }, { autoAlpha: 1, ease: Linear.easeInOut}));
  });
  roadmapTween.add([
    TweenMax.fromTo(
      [$(".roadmap--row .process--after")],
      1,
      { autoAlpha: 0, opacity: 0, width: 0 },
      { autoAlpha: 1, opacity: 1, width: processWidth }
    ),
    TweenMax.fromTo(
      [$(".col-active:nth-child(" + roadmapActive.length + ") .roadmap--active span.dot-active")],
      1,
      { autoAlpha: 0, scale: 0},
      { autoAlpha: 1, scale: 1}
    )
    ]
  );
  // Create the Scene and trigger when visible
  new ScrollMagic.Scene({
    triggerElement: ".roadmap",
    triggerHook: "0.2",
    offset: -250,
    duration: "100%",
    tweenChanges: false,
  })
    .setTween(roadmapTween)
    // .setClassToggle(".roadmap--item.roadmap--active", "fade-in")
    .addTo(controller)

  // addRule(".roadmap--row .process--after", {
  //   width: processWidth,
  // });

  // addRule(".col-active:nth-child(" + roadmapActive.length + ") .roadmap--active span.dot-active", {
  //   border: "8px solid #C2F8FF",
  //   filter: "blur(2px)",
  //   animation: "rippleeff 2s infinite",
  //   opacity: "1",
  // });

  var roadmapTween2 = new TimelineMax().add([
    TweenMax.to(".col-active:nth-child(" + roadmapActive.length + ") .roadmap--quarter .roadmap--badge img", 1, { rotation: 540, ease: Linear.easeNone, delay: 0.5}),
  ]);
  new ScrollMagic.Scene({
    triggerElement: ".roadmap",
    triggerHook: "0",
    offset: 0,
    duration: 1000,
    tweenChanges: false,
  })
    .setTween(roadmapTween2)
    .setPin(".roadmap")
    .addTo(controller)

  //--   Collect to earn --//
  let zodiacText = $(".ecosystem--earn--text");
  let zodiacImg = $(".ecosystem--earn--bg img");
  let zodiacSvg = $(".ecosystem--earn--zodiac");
  var zodiacTween = new TimelineMax().add([
    TweenMax.fromTo(zodiacText, 1, { x: "-50%", autoAlpha: 0 }, { x: "0%", autoAlpha: 1 }),
    TweenMax.fromTo(zodiacImg, 1, { scale: 0 }, {scale: 1, delay: 0.1 }),
    TweenMax.fromTo(zodiacSvg, 1.5, { width: 0, autoAlpha: 0.5 }, { width: "100%", autoAlpha: 1, delay: 1, ease: Linear.easeInOut}),
  ]);

  ecosystemScene = new ScrollMagic.Scene({
    triggerElement: ".ecosystem--row--earn",
    triggerHook: "0.3",
    offset: -150,
    duration: 500,
    tweenChanges: false,
  })
    .setTween(zodiacTween)
    .reverse(true)

  controller.addScene([ecosystemScene]);

  // function sizeAll() {
  //   if ($(window).width() <= 991) {
  //     console.log(roadmapTween);
  //     controller.removeScene([roadmapTween, roadmapTween2]);

  //   }
  // }
  // sizeAll();

});

let snowflakes_count = 50;

// let base_css = ``; // Put your custom base css here

if (typeof total !== "undefined") {
  snowflakes_count = total;
}

// Creating snowflakes
function spawn_snow(snow_density = 200) {
  snow_density -= 1;

  for (let x = 0; x < snow_density; x++) {
    let board = document.createElement("div");
    board.className = "snowflake";

    document.getElementById("snow").appendChild(board);
    // document.getElementById('snow2').appendChild(board);
  }
}

// Append style for each snowflake to the head
function add_css(rule) {
  let css = document.createElement("style");
  css.type = "text/css";
  css.appendChild(document.createTextNode(rule)); // Support for the rest
  document.getElementsByTagName("head")[0].appendChild(css);
}

// Math
function random_int(value = 100) {
  return Math.floor(Math.random() * value) + 1;
}

function random_range(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Create style for snowflake
function spawnSnowCSS(snow_density = 200) {
  let snowflake_name = "snowflake";
  let rule = ``;
  if (typeof base_css !== "undefined") {
    rule = base_css;
  }

  for (let i = 1; i < snow_density; i++) {
    let random_x = Math.random() * 90; // vw
    if (window.screen.width >= 1920) {
      random_x = Math.random() * 80; // vw
    }
    if (window.screen.width >= 2500) {
      random_x = Math.random() * 60; // vw
    }
    if (window.screen.width >= 3600) {
      random_x = Math.random() * 30; // vw
    }
    let random_offset = random_range(-90000, 90000) * 0.00001; // vw;
    let random_x_end = random_x + random_offset;
    // let random_x_end_yoyo = random_x + (random_offset / 3);
    let random_yoyo_time = random_range(10000, 11000) / 100000;
    let random_yoyo_y = random_yoyo_time * 100; // vh
    let random_scale = Math.random();
    let fall_duration = random_range(10, 30) * 2; // s
    let fall_delay = random_int(30) * -1; // s
    let opacity_ = Math.random();

    rule += `
        .${snowflake_name}:nth-child(${i}) {
            opacity: ${opacity_};
            transform: translate(${random_x_end}vw, -1000px) scale(${random_scale});
            animation: fall-${i} ${fall_duration}s ${fall_delay}s ease-in infinite;
        }

        @keyframes fall-${i} {

            from {
                transform: translate(${random_x_end}vw, 100%) scale(${random_scale});
            }
            ${random_yoyo_y}% {
                transform: translate(${random_x_end}vw, ${random_yoyo_y}%) scale(${random_scale});
            }

        }
        `;
  }

  add_css(rule);
}

// Load the rules and execute after the DOM loads
window.onload = function () {
  spawnSnowCSS(snowflakes_count);
  spawn_snow(snowflakes_count);
};
